import React, { useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import "./css/index.css";
import ProtectedRoute from "./components/ProtectedRoute";
import { AccountContext, AccountContextProvider } from "./context/account";
import * as Sentry from "@sentry/react";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import { captureException } from "@sentry/browser";
import { urlToLocale } from "./helpers";
import { getCountryRegion } from "./logic/regions";

const enviroment = process.env.REACT_APP_ENV || "live";

if (enviroment) {
  Sentry.init({
    dsn: "https://fd24ca8d56ed468483ccbb24367f5119@o127200.ingest.sentry.io/6146982",
    integrations: [new TracingIntegrations.BrowserTracing()],
    environment: enviroment,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
  });
}

const Error404 = React.lazy(() => import("./screens/Error404"));

const SubscriptionUpsells = React.lazy(() =>
  import("./screens/account/subscriptions/SubscriptionUpsells")
);
const SubscriptionRefill = React.lazy(() =>
  import("./screens/account/subscriptions/SubscriptionRefill")
);
const Account = React.lazy(() => import("./screens/account/Account"));
const Ambassador = React.lazy(() =>
  import("./screens/account/ambassadors/Ambassador")
);
const AccountSubscriptions = React.lazy(() =>
  import("./screens/account/AccountSubscriptions")
);
const AccountSubscriptionsSingle = React.lazy(() =>
  import("./screens/account/AccountSubscriptionsSingle")
);
const Login = React.lazy(() => import("./screens/Login"));

const ClubLanding = React.lazy(() =>
  import("./screens/LandingExperienceGourmet")
);

const ClubLandingNew = React.lazy(() => import("./screens/LandingClub"));

const AccountPaymentMethods = React.lazy(() =>
  import("./screens/account/AccountPaymentMethods")
);
const AccountNewPaymentMethod = React.lazy(() =>
  import("./screens/account/AccountNewPaymentMethod")
);

const Home = React.lazy(() => import("./screens/ClubHome"));

global.Promise = require("promise");

require("promise/lib/rejection-tracking").enable({
  allRejections: true,
  onUnhandled: (id, error) => {
    console.error(error);
    captureException(error);
  },
});

function RouteMiddleware() {
  const location = useLocation();
  const { region, setRegion } = useContext(AccountContext);
  useEffect(() => {
    const [lang, country] = urlToLocale(location.pathname);

    if (!region) {
      getCountryRegion(country)
        .then((response) => {
          if (response.status === 200) {
            let regionData = response.data;
            if (!regionData.extra.club_info || !regionData.extra.club_price) {
              getCountryRegion("XX")
                .then((response) => {
                  if (response.status === 200) {
                    regionData.extra.club_info = response.data.extra.club_info;
                    regionData.extra.club_price =
                      response.data.extra.club_price;
                    regionData.use_world = true;
                    setRegion(regionData);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              setRegion(regionData);
            }
          }
        })
        .catch(() => {});
    }
  }, [location]);

  return <></>;
}

function AppRouter() {
  useEffect(() => {
    function amm() {
      try {
        var s, d;
        s = localStorage.getItem("amm");
        d = JSON.parse(s);
        if (d) {
          return d;
        }
      } catch (err) {
        console.log(err);
      }
    }

    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    );
    fbq("init", "1983837231849232", amm());
    fbq("track", "PageView");

    (function (i, s, o, g, r, a, m) {
      i["GoogleAnalyticsObject"] = r;
      (i[r] =
        i[r] ||
        function () {
          (i[r].q = i[r].q || []).push(arguments);
        }),
        (i[r].l = 1 * new Date());
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(
      window,
      document,
      "script",
      "https://www.google-analytics.com/analytics.js",
      "ga"
    );

    ga("create", "UA-42631937-5", "auto");
    ga("require", "GTM-NJNWVNZ");
    ga("require", "ec");
    document.addEventListener("DOMContentLoaded", function () {
      ga("send", "pageview");
    });

    !(function (w, d, t) {
      w.TiktokAnalyticsObject = t;
      var ttq = (w[t] = w[t] || []);
      (ttq.methods = [
        "page",
        "track",
        "identify",
        "instances",
        "debug",
        "on",
        "off",
        "once",
        "ready",
        "alias",
        "group",
        "enableCookie",
        "disableCookie",
      ]),
        (ttq.setAndDefer = function (t, e) {
          t[e] = function () {
            t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
          };
        });
      for (var i = 0; i < ttq.methods.length; i++)
        ttq.setAndDefer(ttq, ttq.methods[i]);
      (ttq.instance = function (t) {
        for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
          ttq.setAndDefer(e, ttq.methods[n]);
        return e;
      }),
        (ttq.load = function (e, n) {
          var i = "https://analytics.tiktok.com/i18n/pixel/events.js";
          (ttq._i = ttq._i || {}),
            (ttq._i[e] = []),
            (ttq._i[e]._u = i),
            (ttq._t = ttq._t || {}),
            (ttq._t[e] = +new Date()),
            (ttq._o = ttq._o || {}),
            (ttq._o[e] = n || {});
          n = document.createElement("script");
          (n.type = "text/javascript"),
            (n.defer = !0),
            (n.src = i + "?sdkid=" + e + "&lib=" + t);
          e = document.getElementsByTagName("script")[0];
          e.parentNode.insertBefore(n, e);
        });

      ttq.load("C8770CGJVRJHAP8GSAH0");
      ttq.page();
    })(window, document, "ttq");

    (function () {
      window._rsq = window._rsq || [];
      var rScix = document.createElement("script");
      rScix.type = "text/javascript";
      rScix.defer = true;
      rScix.src =
        ("https:" === document.location.protocol ? "https://" : "http://") +
        "d1stxfv94hrhia.cloudfront.net/waves/v3/w.js";
      document.getElementsByTagName("body")[0].appendChild(rScix);
    })();

    (function () {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "https://182114.tracking.hyros.com/v1/lst/universal-script?ph=55518a23778d3981fb75ba8a5956c64dbd125a52aab869d88e7c13cd0fc0c75e&tag=!tracking";
      script.defer = true;
      document.getElementsByTagName("body")[0].appendChild(script);
    })();

    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.defer = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", "GTM-KW3SVVL");
  }, []);
  return (
    <React.Suspense fallback={<span></span>}>
      <AccountContextProvider>
        <Router>
          <RouteMiddleware />
          <Switch>
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/account"
              render={(props) => (
                <>
                  <React.Suspense fallback={<span></span>}>
                    <ProtectedRoute
                      path="/:lang([a-z]{2})-:country([a-z]{2})/account/profile/subscriptions/list"
                      component={AccountSubscriptions}
                    />
                    <ProtectedRoute
                      {...props}
                      path="/:lang([a-z]{2})-:country([a-z]{2})/account/profile/subscriptions/:type/:uuid/upsells"
                      component={SubscriptionUpsells}
                    />
                    <ProtectedRoute
                      {...props}
                      path="/:lang([a-z]{2})-:country([a-z]{2})/account/profile/subscriptions/:type/:uuid/refill"
                      component={SubscriptionRefill}
                    />
                    <ProtectedRoute
                      {...props}
                      path="/:lang([a-z]{2})-:country([a-z]{2})/account/profile/subscriptions/:type/:uuid/"
                      component={AccountSubscriptionsSingle}
                      exact
                    />
                    <ProtectedRoute
                      path="/:lang([a-z]{2})-:country([a-z]{2})/account/profile/payment_methods/new"
                      component={AccountNewPaymentMethod}
                    />
                    <ProtectedRoute
                      path="/:lang([a-z]{2})-:country([a-z]{2})/account/profile/payment_methods"
                      component={AccountPaymentMethods}
                    />
                    <ProtectedRoute
                      {...props}
                      path={`/:lang([a-z]{2})-:country([a-z]{2})/account/profile/ambassador`}
                      component={Ambassador}
                      exact
                    />
                    <ProtectedRoute
                      {...props}
                      path={`/:lang([a-z]{2})-:country([a-z]{2})/account/`}
                      component={Account}
                      exact
                    />
                  </React.Suspense>
                </>
              )}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/login"
              component={Login}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/club"
              component={ClubLandingNew}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/club-new"
              component={ClubLandingNew}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/"
              component={Home}
            />
            <Route path="/" component={Error404} />
          </Switch>
        </Router>
      </AccountContextProvider>
    </React.Suspense>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>,
  document.getElementById("root")
);
